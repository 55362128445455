import http from "../common-http";

class ProductService {



  // product-item
  get_item() {return http.get("/product/item/");}
  create_item(data: any) {return http.post("/product/create-item/", data);}
  getByTitle(title: any) {return http.get(`/product/get-item/?title=${title}`);}
  
  // item-stock
  get_item_stock() {return http.get("/product/item-stock/");}
  get_item_stock_project(id: any) {return http.get("/product/get-item-stock/"+id);}
  
  create_item_stock(data: any) {return http.post("/product/create-item-stock/", data);}
  create_item_stock_exit(data: any) {return http.post("/product/create-item-stock-exit/", data);}
  
  get_item_reqeust_in(id: any) {return http.get("/product/item-request-in/" +id );}
  get_item_reqeust_out(id: any) {return http.get("/product/item-request-out/" +id);}
  
  get_item_reqeust_in_report(id: any) {return http.get("/product/get-item-stock-in-report/" +id );}
  
  post_item_reqeust_inout_report(data: any) {return http.post("/product/get-item-stock-in-out-report/", data );}
  

// catgories
  get_category(id: string) {
    if(id == ''){return http.get(`/product/category/`);}
    else{return http.get(`/product/category/${id}`); }
  }
  create_category(data: any) {return http.post("/product/category/", data);}
  update_category(id: string, data: any) {return http.put(`/product/category/${id}/`, data);}
  delete_category(id: string) {return http.delete(`/product/category/${id}`);}
  category_by_title(title: string) {return http.get(`/product/category/?title=${title}`);}

// catgories_level_1
  get_sub_level_1_category(id: string) {
    if(id == ''){return http.get(`/product/category-level-1/`);}
    else{return http.get(`/product/category-level-1/${id}`); }
  }
  create_sub_level_1_category(data: any) {return http.post("/product/category-level-1/", data);}
  update_sub_level_1_category(id: string, data: any) {return http.put(`/product/category-level-1/${id}/`, data);}
  delete_sub_level_1_category(id: string) {return http.delete(`/product/category-level-1/${id}`);}
  sub_level_1_category_title(title: string) {return http.get(`/product/category-level-1/?title=${title}`);}

// catgories_level_2
  get_sub_level_2_category(id: string) {
    if(id == ''){return http.get(`/product/category-level-2/`);}
    else{return http.get(`/product/category-level-2/${id}`); }
  }
  create_sub_level_2_category(data: any) {return http.post("/product/category-level-2/", data);}
  update_sub_level_2_category(id: string, data: any) {return http.put(`/product/category-level-2/${id}/`, data);}
  delete_sub_level_2_category(id: string) {return http.delete(`/product/category-level-2/${id}`);}
  sub_level_2_category_title(title: string) {return http.get(`/product/category-level-2/?title=${title}`);}

}

export default new ProductService();