import http from "../common-http";

class ProjectService {

  // project-item
  get_list() {return http.get("/project/list/");}

  get_list_by_id(id: any) {return http.get(`/project/list/${id}/`);}
  
  create_list(data: any) {return http.post("/project/list/", data);}

  getByTitle(title: any) {
    return http.get(`/project/get-project/?title=${title}`);
  }  

  create_project_emp(data: any) {
    return http.post(`/project/employee-project/`, data);
  }  


  getAllprojectorder() {
    return http.get("/project/order/");
  }

  getAllprojectorderlist() {
    return http.get("/project/orderforce/");
  }


// catgories
  // get_category(id: string) {
  //   if(id == ''){return http.get(`/product/category/`);}
  //   else{return http.get(`/product/category/${id}`); }
  // }
  // create_category(data: any) {return http.post("/product/category/", data);}
  // update_category(id: string, data: any) {return http.put(`/product/category/${id}/`, data);}
  // delete_category(id: string) {return http.delete(`/product/category/${id}`);}
  // category_by_title(title: string) {return http.get(`/product/category/?title=${title}`);}

// catgories_level_1
  // get_sub_level_1_category(id: string) {
  //   if(id == ''){return http.get(`/product/category-level-1/`);}
  //   else{return http.get(`/product/category-level-1/${id}`); }
  // }
  // create_sub_level_1_category(data: any) {return http.post("/product/category-level-1/", data);}
  // update_sub_level_1_category(id: string, data: any) {return http.put(`/product/category-level-1/${id}/`, data);}
  // delete_sub_level_1_category(id: string) {return http.delete(`/product/category-level-1/${id}`);}
  // sub_level_1_category_title(title: string) {return http.get(`/product/category-level-1/?title=${title}`);}

// catgories_level_2
  // get_sub_level_2_category(id: string) {
  //   if(id == ''){return http.get(`/product/category-level-2/`);}
  //   else{return http.get(`/product/category-level-2/${id}`); }
  // }
  // create_sub_level_2_category(data: any) {return http.post("/product/category-level-2/", data);}
  // update_sub_level_2_category(id: string, data: any) {return http.put(`/product/category-level-2/${id}/`, data);}
  // delete_sub_level_2_category(id: string) {return http.delete(`/product/category-level-2/${id}`);}
  // sub_level_2_category_title(title: string) {return http.get(`/product/category-level-2/?title=${title}`);}

}

export default new ProjectService();